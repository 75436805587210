import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import Layout from 'src/components/Layout'
import { Inner } from 'src/components/layouts/Inner'
import { PageHeading } from 'src/components/molecules/Heading'
import BannerImage from 'src/images/banner_2.jpg'
import { Link, graphql } from 'gatsby'
import { ServiceListEx } from 'src/components/domains/service/ServiceListEx'
import { ElementContainer } from 'src/components/layouts/ElementContainer'
import { Topics } from 'src/components/domains/index/Topics'
import HeroImage from 'src/components/domains/index/HeroImage'

type Props = {
  data: any
}

const PageIndex: React.FC<Props> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Layout hasFeaturedImage>
        <ElementContainer>
          <HeroImage />
        </ElementContainer>

        <Inner>
          <ElementContainer>
            <Banner>
              <Link to="/recruit">
                <img src={BannerImage} alt="" />
              </Link>
            </Banner>
          </ElementContainer>

          {/* サービス一覧 */}
          <ScPageHeading component="h2" heading="サービス一覧" subHeading="Services" />
          <ElementContainer>
            <ServiceListEx />
          </ElementContainer>

          {/* トピックス */}
          {frontmatter.topics.length > 0 && (
            <>
              <ScPageHeading component="h2" heading="トピックス" subHeading="Topics" />
              <Topics data={frontmatter.topics} />
            </>
          )}
        </Inner>
      </Layout>
    </>
  )
}

export default PageIndex

const Banner = styled.div`
  text-align: center;
  img {
    height: auto;
    max-width: 560px;
    width: 100%;
  }
`

const ScPageHeading = styled(PageHeading)`
  margin-bottom: 1.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
  }
`

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "IndexPage" } }) {
      frontmatter {
        topics {
          date
          text
          link
        }
      }
    }
  }
`
