import React from 'react'
import { Link } from 'gatsby'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'
import { v4 as uuid } from 'uuid'

type Props = {
  data: {
    date: string
    text: string
    link?: string
  }[]
} & ComponentStyleProps

export const Topics: React.FC<Props> = (props) => {
  const { data } = props

  const LinkText = React.useCallback((url: string, text: string) => {
    const regex = /(creaith\.jp|localhost)/
    const isInternal = regex.test(url)
    if (isInternal) {
      return <ScLink to={url}>{text}</ScLink>
    }
    return (
      <ScHref href={url} target="blank" rel="noopener">
        {text}
      </ScHref>
    )
  }, [])

  return (
    <ScWrapper>
      {data.map((v) => (
        <ScItem key={uuid()}>
          <ScDate>{v.date}</ScDate>
          <ScText>{v.link ? LinkText(v.link, v.text) : v.text}</ScText>
        </ScItem>
      ))}
    </ScWrapper>
  )
}

const ScWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 24px;
  ${mediaQuery('sm')} {
    padding: 40px;
  }
`

const ScItem = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  ${mediaQuery('sm')} {
    display: flex;
    align-items: center;
  }
`

const ScDate = styled.div`
  color: #555;
  font-size: 0.75rem;
  margin-bottom: 0.25em;
  ${mediaQuery('sm')} {
    font-size: 0.875rem;
    min-width: 96px;
    margin-bottom: 0;
  }
`

const ScText = styled.div`
  font-size: 0.875rem;
  ${mediaQuery('sm')} {
    font-size: 1rem;
  }
`

const ScLink = styled(Link)`
  color: ${({ theme }) => theme.color.text.main};
  &:hover {
    text-decoration: underline;
  }
`

const ScHref = styled.a`
  color: ${({ theme }) => theme.color.text.main};
  &:hover {
    text-decoration: underline;
  }
`
