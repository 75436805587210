import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import HeroImageBg from 'src/images/hero-image.jpg'

const HeroImage: React.FC = () => {
  return (
    <ScHeroImage>
      <ScTitle>
        <ScBlock>
          今の「当たり前」に固執せず
          <br />
          新しい「当たり前」を
          <br />
          創造する集団です
        </ScBlock>
      </ScTitle>
    </ScHeroImage>
  )
}

export default HeroImage

const ScHeroImage = styled.section`
  background: url(${HeroImageBg}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 16px;
  position: relative;
  margin-right: -16px;
  margin-left: -16px;
  &::before {
    background: linear-gradient(
      0,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 33.333%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(0, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 33.333%, rgba(0, 0, 0, 0.2) 100%);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${mediaQuery('sm')} {
    height: calc(100vh - 86px);
    margin-right: -32px;
    margin-left: -32px;
    padding: 16px;
  }
`

const ScBlock = styled.div`
  display: flex;
  align-items: center;
`

const ScTitle = styled.h1`
  color: #fff;
  position: relative;
  z-index: 1;
  font-size: 1.35rem;
  font-weight: bold;
  @media (min-width: 321px) {
    font-size: 1.5rem;
  }
  ${mediaQuery('sm')} {
    font-size: 5rem;
  }
`
